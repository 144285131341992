import React from "react"
import SectionTitle from "../atoms/SectionTitle"
import IconBox from "../atoms/IconBox"
import ImageRenderer from "../atoms/ImageRenderer"

const ServicesSection = ({ data }) => {
  return (
    <div className="relative">
      <div className="cc-section--horizontal-space pt-16 md:pt-28 pb-6 relative">
        <SectionTitle title={data.sectionTitle} titleEC={`max-w-[465px]`} />
        <div className="flex flex-col md:flex-row gap-[45px] justify-between mt-20">
          {data.iconBoxes.map((item, index) => (
            <div className="flex-[1]" key={`icon_box` + index}>
              <IconBox
                componentEC={item.componentEC}
                image={item.iconImage}
                imageComponentEC={`mb-8`}
                title={item.title}
                titleEC={item.titleEC}
                description={item.description}
                descriptionLength={100}
                btn={item.btn}
                arrowImage={item.arrowImage}
                arrowImageEC={item.arrowImageEC}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ServicesSection
