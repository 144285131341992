import React from "react"
import SectionTitle from "../../atoms/SectionTitle"
import BrandParagraph from "../../atoms/BrandParagraph"
import IconBox from "../../atoms/IconBox"
import ImageRenderer from "../../atoms/ImageRenderer"

const WhatWeDoSection = ({ data }) => {
  return (
    <div className="bg-primary-light pt-16 pb-16">
      <div className="cc-section--horizontal-space">
        <SectionTitle
          title={data.sectionTitle}
          titleEC={`justify-center`}
          componentEC={`text-center`}
        />
        <BrandParagraph text={data.description} textEC={`text-center`} />
        <div className="mt-16 flex flex-col md:flex-row gap-[40px] justify-between">
          <div className="flex-[1] relative">
            <IconBox
              componentEC={`text-center`}
              image={data.iconBox1.iconImage}
              imageComponentEC={`mx-auto mb-8 !bg-transparent !shadow-none`}
              title={data.iconBox1.title}
              description={data.iconBox1.description}
            />
            <div className="hidden md:block absolute w-[210px] max-w-[120px] lg:max-w-[100%] right-[-80px] lg:right-[-120px] top-[20px]">
                <ImageRenderer img={`services--what-we-do-arrow-1`} alt={`services--what-we-do-arrow-1`} />
            </div>
          </div>
          <div className="flex-[1] relative">
            <IconBox
              componentEC={`text-center`}
              image={data.iconBox2.iconImage}
              imageComponentEC={`mx-auto mb-8 !bg-transparent !shadow-none`}
              title={data.iconBox2.title}
              description={data.iconBox2.description}
            />
            <div className="hidden md:block absolute w-[210px] max-w-[120px] lg:max-w-[100%] right-[-80px] lg:right-[-120px] top-[70px]">
                <ImageRenderer img={`services--what-we-do-arrow-2`} alt={`services--what-we-do-arrow-2`} />
            </div>
          </div>
          <div className="flex-[1]">
            <IconBox
              componentEC={`text-center`}
              image={data.iconBox3.iconImage}
              imageComponentEC={`mx-auto mb-8 !bg-transparent !shadow-none`}
              title={data.iconBox3.title}
              description={data.iconBox3.description}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatWeDoSection
