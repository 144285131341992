export const servicesPage = {
  title: `Specialist Disability Accommodation (SDA) at Care Casa: <span>Your Home</span>, Your Way.`,
  description: `Care Casa is dedicated to providing premium Specialist Disability Accommodation (SDA) services that cater to the unique needs of individuals with disabilities. We design homes to enhance comfort, accessibility, and independence.`,
  googleReviewText: `Searching for suitable SDA homes was daunting until I discovered Care Casa. Their approach to balancing independence and comfort is remarkable, and my experience here has been fantastic.`,
}

// AboutUs Section
export const aboutusSection = {
  sectionMainImage: {
    path: `home--aboutus-section-image`,
    alt: `home--aboutus-section-image`,
  },
  sectionTitle: `<span>Our</span> Services`,
  description: `
    Finding a feasible Specialist Disability Accommodation (SDA) solution is undoubtedly daunting. Care Casa genuinely perceives it as convoluted. Therefore, we are committed to offering customised living, care and support plans to help you live comfortably and independently every step of the way. Our experienced professionals work closely with you to provide tailored support and ensure that every accommodation aspect aligns with your unique needs and preferences.
    <br><br>
    Care Casa offers various SDA housing options, from self-contained apartments to shared living arrangements with round-the-clock support. We design our SDA homes to be accessible and inclusive with adaptive equipment, home modifications, medical facilities and digital accessibility. We also provide a range of support services, including daily living assistance, therapy, and social activities.`,
}

// whyUseThisServicesSection Section
export const whyUseThisServicesSection = {
  sectionMainImage: {
    path: `home--aboutus-section-image`,
    alt: `home--aboutus-section-image`,
  },
  sectionTitle: `<span>Why</span> Care Casa?`,
  description: `Care Casa distinguishes itself from other specialist disability accommodation providers in Australia by its commitment to quality, care and facilities. Our top professionals work closely with our respected clients to help them achieve their goals of living a more independent, satisfied and quality lifestyle. Whether you are searching for shared or single-occupant properties, an accessible and socially interactive lifestyle, safe, desirable and quality buildings with the latest technological design and most importantly, independence, comfort and control, we are always here to assist.`,
  list: [
    `Leading professionals with the desired skill set and proven experience managing SDA properties.`,
    `Promote activities and events that foster interaction and inclusivity.`,
    `Flexible to select and receive the best support model that fits your condition.`,
    `Comprehending clients' needs and preferences, offering personalised dwellings and support solutions.`,
    `Participants like and trust our services because of our transparent and result-oriented approach.`,
    `With essential resources and in-depth care, we help our people to live an enriching life.`,
  ],
}

// What We Do Section
export const whatWeDoSection = {
  sectionTitle: `What’s the <span>process?</span>`,
  description: `We have designed our processes to be clear and intuitive, ensuring they are user-friendly and easily comprehended. Our focus on simplicity and efficiency helps you move through every step confidently.`,
  iconBox1: {
    iconImage: {
      path: `services--what-we-do-icon-1`,
      alt: `services--what-we-do-icon-1`,
    },
    title: `Step 1`,
    description: `Define Criteria: To specify options, you must determine key factors such as location, budget, and property type.`,
  },
  iconBox2: {
    iconImage: {
      path: `services--what-we-do-icon-2`,
      alt: `services--what-we-do-icon-2`,
    },
    title: `Step 2`,
    description: `Filter and Compare: Visit our website, where a list of SDA properties is available. Review the showcased homes matching your criteria and compare listings based on key features and prices.`,
  },
  iconBox3: {
    iconImage: {
      path: `services--what-we-do-icon-3`,
      alt: `services--what-we-do-icon-3`,
    },
    title: `Step 3`,
    description: `Visit and Assess: Schedule viewings for the shortlisted choices and evaluate the property’s condition and overall fit.`,
  },
}
